import { useState } from 'react';
import sendEmail from '../utils/sendEmail';

const initialState = {
  name: '',
  email: '',
  message: '',
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const sendEmails = () => {
    //sends email to info@wdfoundation.co.za and client
    sendEmail({
      name,
      email,
      message,
    }).then(() => {
      clearState();
      document.getElementsByName('name')[0].value = '';
      document.getElementsByName('email')[0].value = '';
      document.getElementsByName('message')[0].value = '';
      setIsSuccess(true);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmails();
  };
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate='true' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Project details'
                    required
                    onChange={handleChange}></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                {isSuccess && (
                  <div className='alert alert-success' role='alert'>
                    <span className='success'>{''}</span>
                    <p className='success'>
                      Your message has been successfully sent.
                    </p>
                  </div>
                )}
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 Created by{' '}
            <a
              href='https://www.welcomestudio.co.za/'
              target='no_blank'
              rel='nofollow'>
              Welcome Studio
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
